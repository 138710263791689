<template>
  <div>
    <section class="section is-small">
      <div class="container">
        <p class="subtitle">Connected</p>

        <TagReach :tagId="tagId" />
      </div>
    </section>

    <section class="section is-small">
      <div class="container">
        <p class="subtitle">Times used last 24 months</p>

        <TagUsagePerMonth :tagId="tagId" />
      </div>
    </section>

    <section class="section is-small">
      <div class="container">
        <p class="subtitle">Associated tags</p>

        <AssociatedTags :tagId="tagId" />
      </div>
    </section>
  </div>
</template>

<script>
import tagProvider from '@/providers/tag'

const AssociatedTags = () => import('@/components/Tags/AssociatedTags')
const TagReach = () => import('@/components/Tags/TagReach')
const TagUsagePerMonth = () => import('@/components/Tags/TagUsagePerMonth')

export default {
  components: {
    AssociatedTags,
    TagReach,
    TagUsagePerMonth,
  },

  props: {
    tagId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      tag: null,
      tagSuggestions: [],
      tagUsageItems: [],
      year: new Date().getFullYear(),
    }
  },

  computed: {},

  created() {
    this.getTagById()
  },

  methods: {
    getTagById() {
      tagProvider.methods.getTagById(this.tagId).then((response) => {
        this.tag = response.data
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
